@import "@lora/03-base/colors";

$palette-custom: (
    light:     #fff, // White
    dark:      #000,// Black
);

$dark-mode-colors: (
    background:           #000,
    primary:              #1c304a,
    primary-active:       #305686,
    text:                 #fff,
    border:               #fff,
);

$semantic-colors-custom: (
    primary:             #e00085,
    primary-active:      #e00085,
    secondary:           #fff,
    secondary-active:    #fff,
    text:                #000,
    text-secondary:      #525252,
    success:             #46bf81,
    alert:               #ab000d,
    disabled:            #ccc,
    element-background:  #ccc,
    global-background:   #f1f1f1,
    organism-background: #525252,
    yellow-background:   #f0c054,
    border:              #d8d8d8,
    selected:            #6c6c6c,
    info:                #e00085,
    info-secondary:      #a6d6ff,
    info-tertiary:       #ffeefb,
    info-border:         #e0e0e0,
    form-gray:           #9b9b9b,
    tertiary:            #ebebff,
    gray-dark:           #5f5f5f,
    icon-color:          #8f0055,
    icon-background:     #ff97e3,
    user-menu-background:#fbe7f3,
    user-menu-color:     #e0008c,
    hamburger-background:#fef6fb
);

$semantic-colors: map-extend($semantic-colors-default, $semantic-colors-custom);
$palette: map-extend($palette-default, $palette-custom);