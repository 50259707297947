$font-primary: "FuturaBook", sans-serif;
$font-primary-bold: "FuturaBold", sans-serif;
$font-secondary: "HelveticaNeue", sans-serif;
$font-tertiary: "TitlingGothicBold", sans-serif;
$brecksville-regular: "Brecksville-Regular", sans-serif;
$brecksville-semi-bold: "Brecksville-Semi-Bold", sans-serif;
$futura-now-text-extrabold: "Futura-Now-Text-ExtraBold", sans-serif;
$futura-now-text-medium: "Futura-Now-Text-Medium", sans-serif;
$futura-now-text-regular: "Futura-Now-Text-Regular", sans-serif;
$itc-franklin-gothic-hellenic-condensed: "ITC-Franklin-Gothic-Hellenic-Condensed", sans-serif;
$itc-franklin-gothic-hellenic-extra-condensed: "ITC-Franklin-Gothic-Hellenic-Extra-Condensed", sans-serif;
$PexicoMicroMono: "PexicoMicroMono", sans-serif;
$TitlingGothicMedium: "TitlingGothicMedium", sans-serif;

$font-list: (
    ('HelveticaNeue', 'HelveticaNeue-Regular', normal, normal),
    ('RobotoBlack', 'Roboto-Black', normal, normal),
    ('RobotoBold', 'Roboto-Bold', bold, normal),
    ('Roboto', 'Roboto-Light', normal, normal),
    ('Roboto-Medium', 'Roboto-Medium', normal, normal),
    ('Brecksville-Regular', 'Brecksville-Regular', normal, normal),
    ('Brecksville-Semi-Bold', 'Brecksville-Semi-Bold', 600, normal),
    ('Futura-Now-Text-ExtraBold', 'Futura-Now-Text-ExtraBold', 800, normal),
    ('Futura-Now-Text-Medium', 'Futura-Now-Text-Medium', 500, normal),
    ('Futura-Now-Text-Regular', 'Futura-Now-Text-Regular', normal, normal),
    ('ITC-Franklin-Gothic-Hellenic-Condensed', 'ITC-Franklin-Gothic-Hellenic-Condensed', normal, normal),
    ('ITC-Franklin-Gothic-Hellenic-Extra-Condensed', 'ITC-Franklin-Gothic-Hellenic-Extra-Condensed', normal, normal),
    ('FuturaBold', 'FuturaBold_normal_normal', normal, normal),
    ('FuturaBook', 'FuturaBook_normal_normal', normal, normal),
    ('TitlingGothicBold', 'TitlingGothicFBSkylineBold_normal_normal', normal, normal),
    ('TitlingGothicMedium', 'TitlingGothicFBSkylineMedium_normal_normal', normal, normal),
    ('PexicoMicroMono', 'PexicoMicroMono_normal_normal', normal, normal)
);

@import "@lora/font-settings";