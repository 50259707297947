/// Global font weight used for normal type.
/// @type Keyword | Number
$global-weight-normal: 300;

/// Default line height for all type.
/// @type Number
$global-lineheight: 1.25;

$global-input-primary-height: rem-calc(50);

/// Global value used for all elements that have a border radius.
/// @type Number
$global-radius: 0;

@import "@lora/03-base/global";