$video-asset-info-name-font: 500 #{rem-calc(18)} / 1 $font-primary;
$video-asset-info-name-text-transform: uppercase;
$video-asset-info-name-margin: rem-calc(21 0 24);
$video-asset-info-description-color: color(dark);
$video-asset-info-name-font--large: 500 #{rem-calc(18)} / 1 $font-primary;
$video-asset-info-name-margin--large: rem-calc(0 0 15);
$video-asset-info-description-font: 100 #{rem-calc(14)} / 1.5 $font-primary;
$video-asset-info-description-font--large: 100 #{rem-calc(16)} / 1.25 $font-primary;
$video-asset-icon-height: rem-calc(43);
$video-asset-icon-height--large: $video-asset-icon-height;
$video-asset-icon-width: rem-calc(43);
$video-asset-icon-width--large: $video-asset-icon-width;
$video-asset-info-margin--large: rem-calc(22 0 0);

@import "@lora/05-components/video-asset";