$button-disabled-border: 1px solid color(dark);
$button-padding: rem-calc(15 12);
$button-font-family: $font-tertiary;
$button-font-size: rem-calc(18);
$button-font-weight: 400;
$button-min-width: rem-calc(270);
$button-letter-spacing: 1px;
$button-disabled-border: none;
$button-disabled-color: color(light);
$button-hover-color: color(primary);
$button-hover-background: color(light);
$button-hover-border: 1px solid color(primary);
$button-print-border: 1px solid color(dark);
$button-print-color-hover: color(dark);
$button-print-icon-hover-color: color(dark);
$button-print-icon-margin: rem-calc(0 20 0 0);
$button-print-text-transform: capitalize;
$button-print-letter-spacing: 1px;
$button-print-font-weight: 100;

$button-secondary: (
    background: color(secondary),
    background-hover: color(secondary-active),
    color: color(dark),
    color-hover: color(primary),
    border: 1px solid color(dark),
    border-hover: 1px solid color(primary)
);

$button-palette: (
    secondary: $button-secondary
);

// Social buttons
$button-social-list: (
    facebook: (
        icon: facebook-2,
        icon-color: color(light),
        icon-color-hover: color(primary)
    ),
);

/// Sizes for buttons.
/// @type Map
$button-sizes: (
    tiny: (
        padding: rem-calc(5 12 4),
        font-size: rem-calc(8),
    ),
    small: (
        padding: rem-calc(8 12),
        font-size: rem-calc(10),
    ),
    large: (
        padding: rem-calc(12),
        font-size: rem-calc(18),
    ),
);

/// Default font size for buttons
/// @type Number
$button-font-size: rem-calc(18);

$button-print-text-decoration: none;

@import "@lora/05-components/button";