$simple-navigation-background: color(global-background);
$simple-navigation-color: color(organism-background);
$simple-navigation-text-decoration: underline;
$simple-navigation-font: 500 #{rem-calc(16)} / 1.5 $font-primary;
$simple-navigation-item-text-transform: none;
$simple-navigation-text-transform: none;
$simple-navigation-subcategories-link-color: color(organism-background);
$simple-navigation-subcategories-active-link-color: color(organism-background);
$simple-navigation-item-active-font-weight: 500;

@import "@lora/05-components/simple-navigation";