$video-library-playlist-title-font: 500 #{rem-calc(18)} / 1 $font-primary;
$video-library-playlist-title-text-transform: none;
$video-library-item-border--medium-down: none;
$video-library-item-border-radius--medium-down: 0;
$video-library-item-background--medium-down: color(global-background);
$video-library-item-name-font-size: rem-calc(16);
$video-library-item-name-font-weight: 300;
$video-library-item-name-line-height: 1.25;
$video-library-item-border: none;
$video-library-item-width--medium-down: 100%;
$video-library-item-active-border: none;
$video-library-item-padding--medium-down: rem-calc(10);
$video-library-item-image-margin--large: rem-calc(0 30 0 0);
$video-library-item-name-text-transform: none;
$video-library-item-name-text-transform--large: uppercase;
$video-library-item-name-font-size--large: rem-calc(18);
$video-library-item-name-font-weight--large: 500;
$video-library-item-name-line-height--large: 1;
$video-library-item-description-color--large: color(organism-background);
$video-library-item-description-font-size--large: rem-calc(16);
$video-library-item-description-line-height--large: 1;
$video-library-item-description-font-weight--large: 300;
$video-library-item-name-margin--large: rem-calc(0 0 10);
$video-library-item-margin--large: rem-calc(0 0 15);
$video-library-item-icon-height: rem-calc(43);
$video-library-item-icon-width: rem-calc(43);
$video-library-item-icon-width--large: $video-library-item-icon-width;
$video-library-item-icon-height--large: $video-library-item-icon-height;

@import "@lora/05-components/video-library";