$product-detail-image-zoom-top: auto;
$product-detail-image-zoom-bottom: 0;
$product-detail-image-zoom-right: rem-calc(-18);
$product-detail-image-main-padding: rem-calc(20 30);
$product-detail-image-badge-top: 0;
$product-detail-image-mosaic-item-padding: rem-calc(0 0 30 30);
$product-detail-image-mosaic-services-primary-padding: rem-calc(0 0 0 30);
$product-detail-image-mosaic-padding: 0;
$product-detail-image-mosaic-height: auto;

@import "@lora/05-components/product/product-detail-image";